<template>
    <div>
        <v-overlay :value="overlay" z-index="999999">
            <v-progress-circular indeterminate size="64" />
        </v-overlay>
        <v-data-table
            :headers="headers"
            :items="dataTable"
            class="elevation-1"
            :loading=loadingTable
            :search=search
            item-key="horario_laboral_id"
            loading-text="Buscando horarios..."
            dense
        >
            <template v-slot:top>
                <v-row align="center" justify="center">
                    <v-col 
                        cols=12 
                        md=4 
                        class='text-center'
                    >
                        <v-toolbar-title>
                            <v-icon>mdi-account-switch</v-icon> HORARIOS DE EMPLEADOS
                        </v-toolbar-title>
                    </v-col>
                    <v-col md=6>
                        <v-text-field v-model=search label=Buscar />
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-results>
                No se encontraron empleados
            </template>
            <template v-slot:no-data>
                Sin registros
            </template>
            <template v-slot:item.actions="{item}">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                            color="success" 
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            @click="configurarHorarios(item)"
                        >
                            mdi-calendar-clock
                        </v-icon>
                    </template>
                    <span>Configurar horarios</span>
                </v-tooltip>
                <v-tooltip 
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon 
                       
                            class="mx-2"
                            v-bind="attrs"
                            v-on="on"
                            :style="`color: ${item.color}`"
                            @click="setColorEventos(item)"
                        >
                            mdi-invert-colors
                        </v-icon>
                    </template>
                    <span>Color de eventos</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-dialog
            width="1100px"
            v-model=dialogHorarioEmpleado
            :persistent=true
            no-click-animation
        >
            <v-toolbar 
                style="position: static;"
                class="blue-grey lighten-5"
            >
                <v-app-bar-nav-icon>
                    <template v-slot:default>
                        <v-icon color="primary">mdi-48px mdi-calendar-clock</v-icon>
                    </template>
                </v-app-bar-nav-icon>
                <v-card-title class="justify-center py-0">
                    HORARIOS DEL EMPLEADO {{ empleado.nombre }}
                </v-card-title>
            </v-toolbar>
            <v-card>    
                <v-card-text>
                    <v-form ref="form_horario_empleado">
                        <v-row>
                            <v-col md="4"> 
                                <v-date-picker
                                    v-model="fechasSeleccionada"
                                    :show-current="true"
                                    locale="es"
                                    range
                                />
                                <v-row class="my-3">
                                    <v-col md="6">
                                        <div>
                                            <label for=""><b>Hora desde</b></label>
                                        </div>
                                        <input 
                                            type="time" 
                                            v-model="horaDesde"
                                            class="input-time"
                                        />
                                    </v-col>
                                    <v-col md="6">
                                        <div>
                                            <label for=""><b>Hora hasta</b></label>
                                        </div>
                                        <input 
                                            type="time" 
                                            v-model="horaHasta"
                                            class="input-time"
                                        />
                                    </v-col>
                                </v-row>
                                <v-col class='text-center'>
                                    <v-btn 
                                        class="text-center"
                                        color="success"
                                        @click="guardarHorario"
                                    >
                                        <v-icon>mdi-floppy</v-icon> GUARDAR
                                    </v-btn>
                                    <v-btn 
                                        @click="closeDialogEmpleado"
                                        class="text-center"
                                    >
                                        <v-icon>mdi-cancel</v-icon> CERRAR
                                    </v-btn>
                                </v-col>
                            </v-col>
                            <v-col md="8">
                                <v-row>
                                    <v-col md="12" class="text-center">
                                        <v-row>
                                            <v-col md="12">
                                                <v-autocomplete
                                                    v-model="empleado.party_id"
                                                    dense
                                                    outlined
                                                    label="Empleado"
                                                    hide-details="auto"
                                                    :rules=required
                                                    :items="dataTable"
                                                    item-value="party_id"
                                                    item-text="nombre"
                                                    @change="getHorariosEmpleado"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col md="12">
                                        <v-data-table
                                            :headers="headersHorarioConfigurados"
                                            :items="horariosConfigurados"
                                            :loading=loadingTable
                                            :search=search
                                            :single-select="false"
                                            v-model="horariosSeleccionados"
                                            key="horario_empleado_crm_id"
                                            class="elevation-1"
                                            item-key="horario_empleado_crm_id"
                                            loading-text="Buscando horarios configurados..."
                                            dense
                                            show-select
                                            
                                        >
                                            <template v-slot:item.fechas="{item}">
                                                {{ item.fecha_desde }} - {{ item.fecha_hasta }}
                                            </template>
                                            <template v-slot:item.horas="{item}">
                                                <p  class="my-0" >
                                                  {{ item.hora_desde }} -  {{ item.hora_hasta }}
                                                </p>
                                            </template>
                                            <template v-slot:item.actions="{item}">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon 
                                                            color="error" 
                                                            class="mx-2"
                                                            v-bind="attrs"
                                                            v-on="on"                
                                                            @click="eliminarHorario(item)"                                            
                                                        >
                                                            mdi-delete-forever
                                                        </v-icon>
                                                    </template>
                                                    <span>Eliminar</span>
                                                </v-tooltip>
                                                <v-tooltip 
                                                    bottom 
                                                    v-if="autorizahorarios && !item.autorizado"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon 
                                                            color="success" 
                                                            class="mx-2"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="autorizarHorario(item)" 
                                                        >
                                                            mdi-check-all
                                                        </v-icon>
                                                    </template>
                                                    <span>Autorizar</span>
                                                </v-tooltip>
                                            </template>
                                            <template v-slot:footer.prepend>
                                                <v-btn 
                                                    color="success" 
                                                    @click="autorizarHorarioMasivo"
                                                    class="mx-1"
                                                >
                                                    <v-icon>mdi-flopppy</v-icon> Autorizar
                                                </v-btn>
                                                <v-btn 
                                                    color='red' 
                                                    class="white--text mx-1" 
                                                    @click="eliminaHorarioMasivo"
                                                >
                                                    <v-icon>mdi-delete-forever</v-icon> Eliminar
                                                </v-btn>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            width="350px"
            v-model=dialogColorEvento
            :persistent=true
            no-click-animation
        >
            <v-toolbar 
                style="position: static;"
                class="blue-grey lighten-5"
            >
                <v-app-bar-nav-icon>
                    <template v-slot:default>
                        <v-icon color="primary">mdi-48px mdi-invert-colors</v-icon>
                    </template>
                </v-app-bar-nav-icon>
                <v-card-title class="justify-center py-0">
                    COLOR DEL EVENTO
                </v-card-title>
            </v-toolbar>
            <v-card>
                <v-form ref="form_estatus_evento">
                    <v-card-text class="pt-5"> 
                        <v-color-picker
                            v-model= colorEvento
                            dot-size="20"
                            hide-inputs
                            swatches-max-height="200"
                        />
                    </v-card-text>
                    <v-card-actions>
                        <v-col class='text-center'>
                            <v-btn 
                                @click="storeColorEvento"
                                class="text-center mr-2"
                                color="success"
                            >
                                <v-icon>mdi-floppy</v-icon> GUARDAR
                            </v-btn>
                            <v-btn 
                                @click="dialogColorEvento=false"
                                class="text-center"
                            >
                                <v-icon>mdi-cancel</v-icon> CERRAR
                            </v-btn>
                        </v-col>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import { mapActions, mapState, mapMutations } from 'vuex'
import moment from 'moment'
import Vue from 'vue'

export default {
    name:'HorariosEmpleadosComponent',
    data: () => ({
        headers: [
            { text: 'ID', value: 'party_id' },
            { text: 'Empleado', value: 'nombre' },
            { text: 'Tipo identificación', value: 'tipo_identificacion' },
            { text: 'Identificación', value: 'identificacion' },
            { text: 'Acciones', value: 'actions', sortable: false, width:'180px',align:'center' }
        ],
        headersHorarioConfigurados:[
            { text: 'Fechas', value: 'fechas',width:'180px'},
            { text: 'Horas', value: 'horas',width:'180px'},
            { text: 'Autorizado por', value: 'autorizado_por' },
            { text: 'Acciones', value: 'actions', sortable: false,align:'center',width:'180px'}
        ],
        required: [v => !!v || 'El campo es requerido'],
        overlay: false,
        dialogHorarioEmpleado: false,
        horariosConfigurados:[],
        horariosSeleccionados:[],
        dataTable: [],
        empleado: {},
        search: null,
        fechasSeleccionada:[moment().format('YYYY-MM-DD')],
        horaDesde:null,
        horaHasta:null,
        dialogColorEvento:false,
        colorEvento:'#000'
    }),
    computed:{
        
        ...mapState('master',['loadingTable','tenantId','paramAlertQuestion']),

        ...mapState("access", ["idActionsRol"]),

        autorizahorarios (){

            return this.idActionsRol.some(e => e == 'AUTORIZA_HORARIO_CRM')

        }

    },
    methods:{

        ...mapMutations('master',['setUrl','setLoadingTable','setTitleToolbar']),

        ...mapActions('master',['requestApi','alertNotification']),

        getEmpleados(){

            this.setUrl('horario-empleado')
            this.overlay = true 

            this.requestApi({
                method: 'GET',
                data :{
                    tenantId: this.tenantId
                }
            }).then(res =>{
               
                this.dataTable = res.data.detail.empleados.filter(e => { 
                    return  this.autorizahorarios ? true : ( e.party_id  == JSON.parse(localStorage.getItem('user')).party_id)
                })
                this.overlay=false
            }).catch(()=>{
                
                this.overlay=false
                
            })  

        },

        configurarHorarios(empleado){
            this.empleado.party_id = empleado.party_id
            this.dialogHorarioEmpleado = true
            this.getHorariosEmpleado()

        },

        getHorariosEmpleado(){
            
            this.setUrl(`horario-empleado/${this.empleado.party_id}`)
            this.overlay = true 

            this.requestApi({
                method: 'GET',
                data :{
                    tenantId: this.tenantId,
                }
            }).then(res =>{
                console.log('res',res)
                this.horariosConfigurados = res.data.detail
                this.overlay = false

            }).catch(()=>{
                
                this.overlay=false
                
            })  

        },

        closeDialogEmpleado(){

            this.dialogHorarioEmpleado = false
            this.empleado = {}

        },

        guardarHorario(){

            this.setUrl('horario-empleado')
            this.overlay = true 

            this.requestApi({
                method: 'POST',
                data :{
                    tenantId: this.tenantId,
                    horaDesde: this.horaDesde,
                    horaHasta: this.horaHasta,
                    fechasSeleccionada: this.fechasSeleccionada,
                    ...this.empleado
                }
            }).then(res =>{
 
               try {
                 this.getHorariosEmpleado()
 
                 this.alertNotification({
                     param:{
                         html: res.data.detail.msg
                     }
                 })
                
                 this.overlay=false
               } catch (error) {
                    console.log(error)
               }

            }).catch(()=>{
                
                this.overlay=false
                
            })  

        },

        eliminarHorario(horario){

            let horarioEmpleadoCrmId = horario.horario_empleado_crm_id

            console.log('horarioEmpleadoCrmId',horarioEmpleadoCrmId)
            Vue.swal({
                html: "Esta seguro de eliminar el horario del empleado?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion,
            }).then((result) => {

                if (result.isConfirmed) {

                    this.setUrl(`horario-empleado`)
                    this.overlay = true 

                    this.requestApi({
                        method: 'DELETE',
                        data :{
                            tenantId: this.tenantId,
                            horarioEmpleadoCrmId
                        }
                    }).then(res =>{
                        console.log('res',res)
                        this.alertNotification({
                            param:{
                                html: res.data.detail.msg
                            }
                        })
                        this.getHorariosEmpleado()
                        this.overlay = false

                    }).catch(()=>{
                        
                        this.overlay=false
                        
                    })  

                }

            })

        },

        autorizarHorario(horario){

            let horarioEmpleadoCrmId = horario.horario_empleado_crm_id
        
            Vue.swal({
                html: "Esta seguro de autorizar el horario del empleado?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion,
            }).then((result) => {

                if (result.isConfirmed) {

                    this.setUrl(`horario-empleado/0`)
                    this.overlay = true 

                    this.requestApi({
                        method: 'PUT',
                        data :{
                            tenantId: this.tenantId,
                            horarioEmpleadoCrmId
                        }
                    }).then(res =>{
                        console.log('res',res)
                        this.alertNotification({
                            param:{
                                html: res.data.detail.msg
                            }
                        })
                        this.getHorariosEmpleado()
                        this.overlay = false

                    }).catch(()=>{
                        
                        this.overlay=false
                        
                    })  

                }

            })
        },
        
        autorizarHorarioMasivo(){
           
            if(!this.horariosSeleccionados.length){

                this.alertNotification({
                    param: {
                        html: "Debe seleccionar al menos un horario",
                        timer: 10000,
                        title: "Error",
                        icon: "error",
                        confirmButtonColor: "red",
                    },
                })

                return false

            }

            Vue.swal({
                html: "Esta seguro de autorizar los horarios seleccionados?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion,
            }).then((result) => {

                if (result.isConfirmed) {

                    this.setUrl(`horario-empleado/0`)
                    this.overlay = true 

                    this.requestApi({
                        method: 'PATCH',
                        data :{
                            tenantId: this.tenantId,
                            horariosSeleccionados: this.horariosSeleccionados
                        }
                    }).then(res =>{
                        console.log('res',res)
                        this.alertNotification({
                            param:{
                                html: res.data.detail.msg
                            }
                        })
                        this.getHorariosEmpleado()
                        this.horariosSeleccionados= []
                        this.overlay = false

                    }).catch(()=>{
                        
                        this.overlay=false
                        
                    })  

                }

            })

        },

        eliminaHorarioMasivo(){

            if(!this.horariosSeleccionados.length){

                this.alertNotification({
                    param: {
                        html: "Debe seleccionar al menos un horario",
                        timer: 10000,
                        title: "Error",
                        icon: "error",
                        confirmButtonColor: "red",
                    },
                })

                return false

            }

            Vue.swal({
                html: "Esta seguro de eliminar los horarios seleccionados?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cerrar",
                ...this.paramAlertQuestion,
            }).then((result) => {

                if (result.isConfirmed) {

                    this.setUrl(`horario-empleado`)
                    this.overlay = true 

                    this.requestApi({
                        method: 'PATCH',
                        data :{
                            tenantId: this.tenantId,
                            horariosSeleccionados: this.horariosSeleccionados
                        }
                    }).then(res =>{
                        console.log('res',res)
                        this.alertNotification({
                            param:{
                                html: res.data.detail.msg
                            }
                        })
                        this.getHorariosEmpleado()
                        this.horariosSeleccionados= []
                        this.overlay = false

                    }).catch(()=>{
                        
                        this.overlay=false
                        
                    })  

                }

            })
        },

        setColorEventos(person){

            this.person = person
            this.dialogColorEvento = true

        },

        storeColorEvento(){

            this.setUrl('horario-empleado')
            this.overlay = true 

            this.requestApi({
                method: 'PUT',
                data :{
                    partyId:  this.person.party_id,
                    color: this.colorEvento
                }
            }).then(res =>{

                this.dialogColorEvento= false
                this.getEmpleados()
 
                this.alertNotification({
                    param:{
                        html: res.data.detail.msg
                    }
                })  

                this.overlay=false

            }).catch(()=>{
                
                this.overlay=false
                
            })  

        }

    },
    created(){

        this.getEmpleados()
        
    }
}

</script>

<style>

.input-time{

    border: 1px solid silver;
    padding: 3px 10px;
    border-radius: 5px;

}

</style>